import React from "react";

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  Image,
} from "@heroui/react";

import glassSystemsLogo from "../../../resources/images/logo/logo-gs.png";

const GlassSystemsLogo = () => {
  return (
    <div className="mr-1">
      <Image
        className="mr-10"
        alt="Glass Systems Logo"
        src={glassSystemsLogo}
        width={20}
      />
    </div>
  );
};

export default function GlassNavBar() {
  return (
    <Navbar>
      <NavbarBrand>
        <GlassSystemsLogo />
        <p className="font-bold text-inherit">GlassSystems</p>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link color="foreground" href="#">
            Nosotros
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="#">
            Portafolio
          </Link>
        </NavbarItem>
        <NavbarItem isActive>
          <Link aria-current="page" href="#">
            Productos
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="#">
            Contacto
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem className="hidden lg:flex">
          <Link href="#">Login</Link>
        </NavbarItem>
        <NavbarItem>
          <Button as={Link} color="primary" href="#" variant="flat">
            Sign Up
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
