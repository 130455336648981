import React from "react";

import robertProfileImege from "../../../resources/images/team/roberto.jpg";
import beatrizProfileImege from "../../../resources/images/team/beatriz.jpeg";
import braulioProfileImege from "../../../resources/images/team/braulio.jpg";
import GlassTeamCard from "./GlassTeamCard/GlassTeamCard";

const glassSystemsTeam = [
  {
    name: "Jose Roberto",
    email: "rodriguez.mendoza.1iv11@gmail.com",
    role: "Backend Developer",
    imageUrl: robertProfileImege,
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
  {
    name: "Braulio Flores",
    email: "braulio809908.38@gmail.com",
    role: "FrontEnd Developer",
    imageUrl: braulioProfileImege,
    lastSeen: null,
  },
  {
    name: "Beatriz García",
    email: "beatrizgre@gmail.com",
    role: "BackEnd / UX",
    imageUrl: beatrizProfileImege,
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
];

const GlassTeam = () => {
  return (
    <div className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <p className="mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-5xl">
          Glass Team
        </p>
        <h2 className="mt-6 mx-auto max-w-lg text-center text-base/7 font-semibold text-indigo-600">
          El equipo que te acompañara a encontrar y construir las mejores
          soluciones tecnológicas
        </h2>

        <div className="mt-5 grid gap-4 place-content-center sm:grid-cols-6 lg:grid-cols-3">
          {glassSystemsTeam.map((memberGlassTeam) => (
            <GlassTeamCard memberGlassTeam={memberGlassTeam} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GlassTeam;
