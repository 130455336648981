import React from "react";

import GlassNavBar from "../../components/ui/GlassNavBar/GlassNavBar";
import GlassCover from "../../components/ui/GlassCover/GlassCover";
import GlassTeam from "../../components/ui/GlassTeam/GlassTeam";
import GlassProducts from "../../components/ui/GlassProducts/GlassProducts";
import GlassFooter from "../../components/ui/GlassFooter/GlassFooter";

const LandingGlassPage = () => {
  return (
    <>
      <GlassNavBar />
      <GlassCover />
      <GlassProducts />
      <GlassTeam />
      <GlassFooter />
    </>
  );
};

export default LandingGlassPage;
