import LandingGlassPage from "./pages/LandingGlassPage/LandingGlassPage";
import {HeroUIProvider} from "@heroui/react";

function App() {
  return (
    <HeroUIProvider>
      <LandingGlassPage/>
    </HeroUIProvider>
  );
}

export default App;
