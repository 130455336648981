import React from "react";

import {Card, CardHeader, CardBody, Image} from "@heroui/react";

const GlassTeamCard = ({memberGlassTeam}) => {

    const { name, role, email, imageUrl } = memberGlassTeam;

  return (
    <Card className="py-4 max-w-80 hover:scale-105 transition-transform duration-300 sm:max-w-none col-span-3 lg:col-span-1">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
        <p className="text-tiny uppercase font-bold">{role}</p>
        <small className="text-default-500">{email}</small>
        <h4 className="font-bold text-large">{name}</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-2">
        <Image
          alt="Card background"
          className="object-cover rounded-xl"
          src={imageUrl}
          height={220}
        />
      </CardBody>
    </Card>
  );
};

export default GlassTeamCard;
