import React from "react";

import { Image, Link } from "@heroui/react";

import glassSystemsLogo from "../../../resources/images/logo/logo-gs.png";

const GlassSystemsLogo = () => {
    return (
      <div className="mr-3">
        <Image
          className="mr-10"
          alt="Glass Systems Logo"
          src={glassSystemsLogo}
          width={40}
        />
      </div>
    );
  };

const GlassFooter = () => {
  return (
    <footer className="bg-gray-50 py-8">
      <div className="container mx-auto flex flex-col items-center sm:flex-row justify-between">
        {/* Sección de navegación */}
        <div className="mb-4 sm:mb-0">
          <Link
            color="foreground"
            href="#"
            className="text-xl font-bold tracking-wider"
          >
            <GlassSystemsLogo/> Glass Systems
          </Link>
        </div>

        {/* Enlaces de navegación */}
        <ul className="flex space-x-4 mb-4 sm:mb-0">
          <li>
            <Link href="#" color="foreground" className="hover:text-gray-300">
              Inicio
            </Link>
          </li>
          <li>
            <Link href="#" color="foreground" className="hover:text-gray-300">
              Servicios
            </Link>
          </li>
          <li>
            <Link href="#" color="foreground" className="hover:text-gray-300">
              Nosotros
            </Link>
          </li>
          <li>
            <Link href="#" color="foreground" className="hover:text-gray-300">
              Contacto
            </Link>
          </li>
        </ul>

        {/* Redes sociales */}
        <div className="flex space-x-4">
          <Link href="#" color="foreground" className="hover:text-gray-300">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M22.46 6c-.77.35-1.61.59-2.48.7a4.3 4.3 0 0 0 1.87-2.37 8.5 8.5 0 0 1-2.71 1.03 4.27 4.27 0 0 0-7.29 3.89 12.13 12.13 0 0 1-8.8-4.46 4.27 4.27 0 0 0 1.32 5.7 4.2 4.2 0 0 1-1.94-.53v.05a4.27 4.27 0 0 0 3.42 4.18 4.27 4.27 0 0 1-1.94.07 4.27 4.27 0 0 0 3.98 2.96 8.56 8.56 0 0 1-5.3 1.83 8.65 8.65 0 0 1-1.02-.06 12.1 12.1 0 0 0 6.56 1.92c7.87 0 12.18-6.52 12.18-12.18 0-.18 0-.36-.01-.54A8.64 8.64 0 0 0 22.46 6z" />
            </svg>
          </Link>
          <Link href="#" color="foreground" className="hover:text-gray-300">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2.04c-5.52 0-10 4.48-10 10 0 4.42 3.59 8.07 8.25 8.84.6.11.82-.26.82-.58v-2.02c-3.34.73-4.04-1.55-4.04-1.55-.55-1.42-1.35-1.8-1.35-1.8-1.1-.75.08-.74.08-.74 1.2.08 1.83 1.23 1.83 1.23 1.08 1.84 2.83 1.31 3.52 1 .1-.78.42-1.31.76-1.61-2.66-.3-5.46-1.33-5.46-5.94 0-1.31.47-2.38 1.24-3.22-.12-.3-.54-1.51.12-3.15 0 0 1.01-.32 3.3 1.23a11.54 11.54 0 0 1 3.01-.41c1.02.01 2.04.14 3.01.41 2.29-1.55 3.3-1.23 3.3-1.23.66 1.64.24 2.85.12 3.15.78.84 1.24 1.91 1.24 3.22 0 4.62-2.8 5.63-5.47 5.93.43.37.82 1.11.82 2.23v3.3c0 .32.22.7.83.58A9 9 0 0 0 22 12c0-4.96-4.02-9-9-9z" />
            </svg>
          </Link>
          <Link href="#" color="foreground" className="hover:text-gray-300">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M22.23 0H1.77C.79 0 0 .8 0 1.77v20.46C0 23.2.79 24 1.77 24H12.8v-9.29H9.69v-3.63h3.12V8.41c0-3.1 1.89-4.79 4.65-4.79 1.32 0 2.45.1 2.78.14v3.22h-1.9c-1.49 0-1.77.7-1.77 1.74v2.28h3.54l-.46 3.63h-3.08V24h6.05C23.2 24 24 23.2 24 22.23V1.77C24 .79 23.2 0 22.23 0z" />
            </svg>
          </Link>
        </div>
      </div>

      {/* Texto de copyright */}
      <div className="mt-8 text-center text-sm text-gray-400">
        © 2025 Glass Systems. Todos los derechos reservados.
      </div>
    </footer>
  );
};

export default GlassFooter;
